<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <span>Contrôle de gestion</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Contrôle de Gestion
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row my-4">
      <div class="div_left"
           v-if="(user[0] == 'ADMIN' || user[0] == 'DCG')  && user[1] == 'FER'">
        <a class="btn btn-blue px-5"
           data-toggle="modal"
           ref="modal_button"
           data-target="#exampleModal">Importer données historiques</a>
      </div>
      <div v-if="(user[1] == 'FER' && (user[0] == 'ADMIN'|| user[0]=='DCG'))"
           class="div_left ml-4">
        <router-link :to="{ path: '/analyses/ajoutDeTransactionControleGestion' }"
                     class="btn btn-blue">
          Enregistrement budgétaire
        </router-link>
      </div>
    </div>
    <div class="row my-4">
      <!-- <div class="col-4 my-3">
        <router-link :to="{ name: 'addComptable' }"
                     class="btn btn-blue">
          Ajouter un exercice comptable
        </router-link>
      </div> -->
      <!-- <div class="col-6 my-3"
           v-if="user[0] == 'AUDIT' && user[1] == 'FER'">
        <button class="btn btn-blue pl-5 pr-5 op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#exampleModal">Importer données historiques</button>
      </div> -->
    </div>
    <div class="row mb-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <label for="">Base: </label>
          <select class="form-control mx-2" 
                  name="" 
                  id=""
                  v-model="base">
            <option value="CONSOLIDEE">Consolidé</option>
            <option value="PEAGE">Péage</option>
            <option value="PESAGE">Pesage</option>
            <option value="SIEGE">Siège</option>
            <option value="AIRE_STATIONNEMENT">Aire de stationnement</option>
          </select>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="change()">
            Rechercher
          </button>
        </div>
      </form>
      <!-- <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Année: </label>
          <select class="form-control mx-2" 
                  name="" 
                  id=""
                  v-model="annee"
                  @change="change()">
            <option :value="annee - 5">{{ annee - 5 }}</option>
            <option :value="annee - 4">{{ annee - 4 }}</option>
            <option :value="annee - 3">{{ annee - 3 }}</option>
            <option :value="annee - 2">{{ annee - 2 }}</option>
            <option :value="annee - 1">{{ annee - 1 }}</option>
            <option :value="annee">{{ annee }}</option>
            <option :value="annee + 1">{{ annee + 1 }}</option>
            <option :value="annee + 2">{{ annee + 2 }}</option>
            <option :value="annee + 3">{{ annee + 3 }}</option>
            <option :value="annee + 4">{{ annee + 4 }}</option>
            <option :value="annee + 5">{{ annee + 5 }}</option>
          </select>
        </div>
      </form> -->
    </div>
    <ul class="nav nav-tabs" 
        id="myTab" 
        role="tablist">
      <li class="nav-item" 
          role="presentation">
        <a class="nav-link active" 
           id="home-tab" 
           data-toggle="tab" 
           href="#home" 
           role="tab" 
           aria-controls="home" 
           aria-selected="true"
           @click="selection('journal')">Balance</a>
      </li>
      
      <!-- <li class="nav-item" 
          role="presentation">
        <a class="nav-link" 
           id="contact-tab" 
           data-toggle="tab" 
           href="#contact" 
           role="tab" 
           aria-controls="contact" 
           aria-selected="false"
           @click="selection('bilan')">Bilan</a>
      </li>
      <li class="nav-item" 
          role="presentation">
        <a class="nav-link" 
           id="compte-tab" 
           data-toggle="tab" 
           href="#compte" 
           role="tab" 
           aria-controls="compte" 
           aria-selected="false"
           @click="selection('resultat')">Compte résultat</a>
      </li> -->
      <!-- <li class="nav-item" 
          role="presentation">
        <a class="nav-link" 
           id="correspondance-tab" 
           data-toggle="tab" 
           href="#correspondance" 
           role="tab" 
           aria-controls="correspondance" 
           aria-selected="false"
           @click="selection('correspondance')">Correspondance des comptes</a>
      </li> -->
    </ul>
    <div class="tab-content" 
         id="myTabContent">
      <div class="tab-pane 
           fade show active" 
           id="home" 
           role="tabpanel" 
           aria-labelledby="home-tab">
        <PlanComptableCG :compte="comptes"
                         :year="years"
                         v-if="showing =='journal'"/>
      </div>
      <div class="tab-pane fade" 
           id="profile" 
           role="tabpanel" 
           aria-labelledby="profile-tab">
        <JournalCG/>
      </div>
      <div class="tab-pane fade" 
           id="contact" 
           role="tabpanel" 
           aria-labelledby="contact-tab">
        <BilanCG :year="years"
                 v-if="showing =='bilan'"/>    
      </div>
      <div class="tab-pane fade" 
           id="compte" 
           role="tabpanel" 
           aria-labelledby="compte-tab">
        <ResultatsCG :year="years"
                     v-if="showing =='resultat'"/>
      </div>
      <!-- <div class="tab-pane fade" 
           id="correspondance" 
           role="tabpanel" 
           aria-labelledby="correspondance-tab">
        <CorrespondanceCG :compte="comptes"
                          v-if="showing =='correspondance'"/>
      </div> -->
    </div>
    <!-- Modal relevé bancaire-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Importer données historiques
                </div>
              </div>
            </div>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-10">
                  <label for="">
                    Exécutions budgétaires
                  </label>
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.donnees_histo }}
                    </label>
                    <input type="file"
                           name="etat_comptable"
                           class="custom-file-input"
                           id="donnees_histo"
                           ref="donnees_histo"
                           v-on:change="handleFileUpload('donnees_histo')"
                           accept=".xlsx,.csv" />
                    <span v-if="form.errors().has('donnees_histo')"
                          v-text="form.errors().get('donnees_histo')"
                          class="errorMsg"> </span>
                  </div>
                  <div class="form-group col-md-1 pt-4">
                    <Loader v-show="loading"/>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    @click="templateDownload('executionBudgetaireFinal.xlsx')">Télécharger un template</button>
            <button type="button"
                    class="btn btn-f-blue"
                    @click="closeModal()"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-f-blue"
                    :disabled="url == ''"
                    @click="submit">Importer</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<!-- <style>
table.table.table-striped tbody td,
table.table.table-striped tfoot td,
table.table.table-striped thead th{
  font-size: 0.54em;
}
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
/* form.form-inline input,
form.form-inline select, */
li.nav-item,
form.form-inline button{
  font-size: 0.6em;
}
form.form-inline input{
  height: fit-content;
}
</style> -->
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import JournalCG from "./JournalCG.vue"
import PlanComptableCG from "./PlanComptableCG.vue"
import BilanCG from "./BilanCG.vue"
import ResultatsCG from "./ResultatsCG.vue"
// import CorrespondanceCG from "./CorrespondanceCG.vue"
import utils from "@/assets/js/utils"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import Loader from "@/components/shared/Spinner"

export default {
  name: "ControleDeGestion",
  components:{
    JournalCG,
    PlanComptableCG,
    BilanCG,
    ResultatsCG,
    // CorrespondanceCG,
    Notif,
    Loader,

  },
  data: () => ({
    eFiscauxData: {
      libelle: "",
      order: "",
      tri: ""
    },
    index: 1,
    url:"",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    base: "CONSOLIDEE",
    years:{
      annee_debut:new Date().getFullYear()+"-01-01",
      annee_fin:new Date().getFullYear()+"-12-31",
      base:"CONSOLIDEE"
    },
    uploadfile :"",
    inputFiles: {  
      donnees_histo:"" 
    },
    listExtensionFiles: [],
    showing:"journal",
    form: form({
      donnees_histo:"" 
    })
      .rules({
        donnees_histo:"required" 
      })
      .messages({
        donnees_histo:"Ce champ est requis" 
      }),
    long: "",
    comptes:false,
    googUrl: "",
    loaded: false,
    loading:false
  }),
  watch: {
    donneesFiscaux() {
      if (this.donneesFiscaux.donnees) {
        this.loaded = true
        this.long = this.donneesFiscaux.donnees.length
      }
      //console.log(this.long)
    },
    msgSuccessJournalCG(){
      if (this.msgSuccessJournalCG) {
        console.log(this.msgSuccessJournalCG)
        this.comptes = true
        this.notif.message = this.msgSuccessJournalCG
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgSuccessJournaCG("")
            this.form[this.uploadfile] = ""
            this.$refs["donnees_histo"].value=""
            this.inputFiles["donnees_histo"]=""
            this.$refs["modal_button"].click()
          }.bind(this),
          3000
        )
      }
    },
    msgFailJournal(){
      if (this.msgFailJournal) {
        console.log(this.msgFailJournal)
        this.loading=false
        this.comptes = true
        this.notif.message = this.msgFailJournal
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgFailExecution("")
            this.$refs["donnees_histo"].value=""
            this.inputFiles["donnees_histo"]=""
          }.bind(this),
          3000
        )
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.form[this.uploadfile]
        this.loading=false
      }
    }
  },
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    // this.getDonnesFiscaux(null)
    this.listExtensionFiles = utils.formaFichierAccepte
  },
  computed: {
    ...mapGetters(["donneesFiscaux","files","msgFailJournalCG","msgSuccessJournalCG"])
  },
  methods: {
    ...mapActions(["getDonnesFiscaux", "pageEFiscaux","saveFile","importerJournalCG"]),
    ...mapMutations(["setDonneesFiscaux","setMsgSuccessExecution","setMsgSuccessJournalCG","setMsgFailJournalCG","setFile"]),
    detailsFiscaux(id) {
      this.$router.push({ path: "/analyses/comptabilite/detail_comptable/" + id })
    },
    paging(url) {
      if (this.index == 0) {
        this.index = 1
      }
      this.googUrl = url.split("/")
      //console.log(this.googUrl)
      url = this.googUrl[1]
      this.pageEFiscaux(url)
    },
    handleFileUpload(filename) {
      if (this.$refs[filename].files[0]) {
        this.inputFiles[filename] = this.$refs[filename].files[0].name
        if (!this.inputFiles[filename].includes(" ")) {
          this.loading=true
          var extension = this.inputFiles[filename].split(".")[1]
          if (this.listExtensionFiles.includes(extension)) {
            this.uploadfile = filename
            this.saveFile(this.$refs[filename].files[0])
          } else {
            this.$refs[filename].disabled = false
            this.inputFiles[filename] = ""
            this.loading=false
            this.notif.message = "Mauvais Format du fichier"
            this.notif.type = "toast-danger"
            this.notif.activated = "activated"
            setTimeout(
              function() {
                this.notif.activated = ""
                this.setErors("")
                
              }.bind(this),
              5000
            )
          }  
        }else{
          this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          this.loadrarc = false

          setTimeout(
            function() {
              this.notif.activated = ""
              this.$refs[filename].value=""
              this.inputFiles[filename]=""
            }.bind(this),
            3000
          )
        }
      }
    },
    submit() {
      this.importerJournalCG({url:this.url})
    },
    changeYear(){
      this.years=""
      this.years.annee_debut = this.annee_debut
      this.years.annee_fin = this.annee_fin
      var save = this.showing
      if (save !="journal") {
        this.showing=""
        setTimeout(
          function() {
            this.showing= save
          }.bind(this),
          500
        )
      }
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    change(){
      this.years.annee_debut = ""
      this.years.annee_fin = ""
      this.years.base = ""
      this.years.annee_debut = this.annee_debut
      this.years.annee_fin = this.annee_fin
      this.years.base = this.base
      var save = this.showing
      this.showing=""
      setTimeout(
        function() {
          this.showing= save
        }.bind(this),
        500
      )
    },
    selection(key){
      this.showing= key
    },
    closeModal(){
      this.setMsgFailExecution("")
      this.$refs["donnees_histo"].value=""
      this.inputFiles["donnees_histo"]=""
    },
    filtrer() {
      this.loaded = false
      this.long = null
      this.setDonneesFiscaux("")
      this.getDonnesFiscaux(this.eFiscauxData)
    },
    restoring() {
      this.loaded = false
      this.long = null
      this.setDonneesFiscaux("")
      this.getDonnesFiscaux(null)
      this.eFiscauxData = { libelle: "", order: "", tri: "" }
    }
  }
}
</script>
